<template>
  <div id="publication-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Reporting") }}<v-chip
          outlined
          class="ml-2"
        >
          {{ totalItems }}
        </v-chip>
        <v-spacer />
        <export-excel
          v-if="$can('download','Report')"
          :options="downloadObject"
          @refetch-data="() => fetchItems(true, 'reporting')"
        />
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="filters"
        :items="items"
        class="fixed-table"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="false"
        dense
        fixed-header
        :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <template #item="options">
          <Row
            :options="options"
            :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Report' }"
          >
            <!-- name -->
            <template #[`item.author.full_name`]="{item}">
              <div
                v-if="item.author && item.author.id"
                class="d-flex align-center"
              >
                <v-avatar
                  :color="item.author.avatar ? '' : 'primary'"
                  :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.author.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.author.full_name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <router-link
                    :to="{ name : 'author-view', params : { id : item.author_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.author.full_name }}
                  </router-link>
                  <!--              <small>@{{ item.publicationname }}</small>-->
                </div>
              </div>
            </template>
            <template #[`item.author.old_author`]="{item}">
              <v-icon
                v-if="item.author && item.author.old_author"
                small
                color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
            </template>
            <template #[`item.author.published`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.published"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <template #[`item.is_text_cover_finished`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.is_text_cover_finished"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <!-- pay -->
            <template #[`item.country`]="{item}">
              <div
                v-if="item.country"
                class="d-flex align-center"
              >
                <span class="text-capitalize">{{ item.country.name }}</span>
              </div>
            </template>
            <template #[`item.is_reading1`]="{item}">
              <span>
                <v-icon
                  v-if="item.is_reading"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </span>
            </template>
            <template #[`item.completion_date`]="{item}">
              <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{ formatDate(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
            </template>
            <!-- status -->

            <!--   /Resiliatioin     -->
            <template #[`item.author.email`]="{item}">
              <span v-if="item.author && item.author.email"> {{ item.author.email.join(', ') }}</span>
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                v-if="item.status && item.status.id"
                small
                :color="item.status.color"
                :class="`${item.status.color}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.status.name }}
              </v-chip>
              <!--          <manuscript-status-chip
            :object="item"
            @refetch-data="fetchManuscripts"
          />-->
            </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxMarkedCircle,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import ExportExcel from '@/components/ExportExcel'
import TableRow from '@/components/TableRow'
import Row from '@/components/Row'

export default {
  components: {
    Row,
    TableRow,
    ExportExcel,
    HeaderFilters,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      stateFilter,
      publicationStates,
      item,
      items,
      tabFilter,
      downloadObject,
      tableCommonColumns,
      tableCommonColumnsReport,
      signatureTableColumnsForReport,
      textTableColumnsForReport,
      collectTableColumnsForReport,
      coverTableColumnsForReport,
      supervisionTableColumnsForReport,
      publicationTableColumnsForReport,
      paymentTableColumnsForReport,
      resiliationTableColumnsForReport,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems, tableHeight,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      showResiliation,
      showCommunication,
    } = controller()

    const assistants = ref([])
    const assistantsPub = ref([])
    const assistantsCommunication = ref([])
    const superiors = ref([])
    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Publication', value: 'publication' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]



    const assistantCommunication = ref(
      {
        text: 'Assistant communication',
        value: 'communication.assistant.code',
        align: 'center',
        action: 'assistant',
        sortable: false,
        filter: {
          place_holder: 'CODE',
          type: 'select',
          values: [],
          label: 'code',
          id: 'id',
          multiple: true,
          column: 'communications.assistant_id',
          join_table: 'users',
          key: 'users.id',
          join_column: 'communications.assistant_id',
          local_column: 'users.id',
        },
      },
    )

    tabFilter.value = 'Report'
    store
      .dispatch('app-publication/fetchCountries', { per_page: 500 })
    store
      .dispatch('app-publication/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchGenres', { per_page: 500 })
    store
      .dispatch('app/fetchFormats', { per_page: 500 })
    axios
      .get('/statuses', { params: { per_page: 500, type: 'publication' } })
      .then(response => {
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))

    if (ability.can('assistant', 'Cover')) {
      axios
        .get('/listWorkers', {
          params: {
            term: '', type: 'text_cover', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = assistants.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              assistants.value.push(u)
            }
          }
          const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
          if (indexH > 0) {
            filters.value[indexH].filter.values = assistants.value
          }
        })
    }
    if (ability.can('assistant', 'Publication')) {
      axios
        .get('/listWorkers', {
          params: {
            term: '', type: 'publication', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = assistantsPub.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              assistantsPub.value.push(u)
            }
          }
          const indexH = filters.value.findIndex(ele => ele.value === 'assistant_publication.code')
          if (indexH > 0) {
            filters.value[indexH].filter.values = assistantsPub.value
          }
        })
    }
    if (ability.can('assistant', 'Communication')) {
      axios
        .get('/listWorkers', {
          params: {
            term: '', type: 'communication', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = assistantsCommunication.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              assistantsCommunication.value.push(u)
            }
          }

          assistantCommunication.value.filter.values = assistantsCommunication.value
        })
    }


    // stateFilter.value = [publicationStates.value.CREATED_STATE, publicationStates.value.SEND_STATE, publicationStates.value.RESEND_STATE]

    filters.value = [
      ...tableCommonColumnsReport,

      ...signatureTableColumnsForReport,
      ...collectTableColumnsForReport,
      ...textTableColumnsForReport,

      ...coverTableColumnsForReport,
      ...supervisionTableColumnsForReport,
      assistantCommunication.value,
      ...publicationTableColumnsForReport,
      ...paymentTableColumnsForReport,
      ...resiliationTableColumnsForReport,
      {
        text: 'E-mail',
        value: 'author.email',
        align: 'center',
        url: 'author_email',
        blockBg: 'bg-grey',
        type: 'text',
        date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
        colors: 'publication_colors',
        comments: 'publication_records',
        color_reference: 'colomn_name',
        comment_reference: 'type',
        comment_column: 'author.email',
        editable: false,
        hideValue: true,
        info: 'Confirmez-vous que l\'email a été fait ?',

        /* fields: [
          {
            type: 'color',
            label: 'Couleur de fond',
            value: 'color_id',
            item_value: 'id',
            item_text: 'color_name',
            items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          },
          {type: 'textarea', label: 'Commentaire', value: 'comment'},
        ], */
        /* functions: [
          (th, td) => {
            if (td.publishing_bod) {
              if (td.publication_colors && td.publication_colors.length > 0) {
                const recordsT = td.publication_colors.filter(ele => ele.colomn_name === 'publishing_bod').sort((a, b) => b.id - a.id)
                if (recordsT.length === 0 || !recordsT[0].color || !recordsT[0].color.id || recordsT[0].color_id != (store.state.app.settings.publication_bod_status || 1)) {

                } else {
                  return true
                }
              }
            }

            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = {'w-100': true, 'h-100': true/!* 'add-padding': true *!/}
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          },
        ], */
        filter: {
          place_holder: 'Email', type: 'text', menu: false, range: true,
        },
        filters: [

          {
            text: 'Couleur de fond',
            place_holder: 'Couleur de fond',
            type: 'select',
            values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
            label: 'color_name',
            id: 'id',
            multiple: true,
            key: 'publication_colors.colomn_name',
            join_table: 'publication_colors',
            join_column: 'publication_colors.publication_id',
            local_column: 'publications.id',
            conditions: [
              {
                name: 'publication_colors.colomn_name',
                value: 'bnf_form',
                single: true,
              },
              {
                name: 'publication_colors.color_id',
                value: null,
                single: false,
              },
            ],
          },
          {
            text: 'Condition',
            place_holder: 'Condition',
            type: 'select',
            is_condition: true,
            values: [
              { id: 0, label: 'Est vide' },
              { id: 1, label: 'N\'est pas vide' },
            ],
            label: 'label',
            id: 'id',
            multiple: false,
            key: 'publication_bnf_form',
            column: 'bnf_form',
          },
        ],
      },
    ]/* .filter((ele) => !ele.action || ability.can(ele.action, 'Manage')) */

    showResiliation.value = true
    showCommunication.value = true

    const formatDateShort = (date, option) => formatDate(date, option)

    axios
      .get('/publications/text/settings', {
        params: {
        },
      })
      .then(response => {
        if (response.data && response.data.errors_before_corrections) {
          const indexH = filters.value.findIndex(ele => ele.value === 'errors_before_correction')
          if (indexH > 0) {
            const errors = filters.value[indexH].filters[0].values = response.data.errors_before_corrections.map(ele => ele.errors_before_correction)

            filters.value[indexH].filters[0].min = errors[0]
            filters.value[indexH].filters[0].max = errors[errors.length - 1]

            // catch for out of range error
            // filters.value[indexH].filters[0].value = [errors[0], errors[errors.length - 1]]
          }
        }
        if (response.data && response.data.error_per_pages) {
          const indexH1 = filters.value.findIndex(ele => ele.value === 'error_per_page')
          if (indexH1 > 0) {
            const errors = filters.value[indexH1].filters[0].values = response.data.error_per_pages.map(ele => ele.error_per_page)

            filters.value[indexH1].filters[0].min = errors[0]
            filters.value[indexH1].filters[0].max = errors[errors.length - 1]

            // catch for out of range error
            // filters.value[indexH1].filters[0].value = [errors[0], errors[errors.length - 1]]

            /* filters.value[indexH1].filters[0].values = response.data.error_per_pages.map((ele) => {
              return {
                id: ele.error_per_page, label: ele.error_per_page,
              }}) */
          }
        }
      })

    axios
      .get('/genres', { params: { per_page: 500, type: 'publication' } })
      .then(response => {
        store.state['app/genres'] = response.data.data
        const indexGenre = filters.value.findIndex(ele => ele.value === 'genre.label')
        if (indexGenre > 0) {
          filters.value[indexGenre].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))

    axios
      .post('/particular_case/list', { page: 1000 })
      .then(response => {
        //store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'manuscript.particular_case.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data
          filters.value[indexH].fields[0].items = response.data
        }
      }).catch(error => console.log(error))

    return {
      item,
      items,
      downloadObject,
      superiors,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      formatDateShort,
      statusFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems,
      tableHeight,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckboxMarkedCircleOutline,
        mdiCheckboxMarkedCircle,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
